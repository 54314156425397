import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class DownloadService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/download`;
    }

    paginate(data, index = null) {
        let url = `${this.#api}`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }


    all() {
        let url = `${this.#api}/active/all`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    sort(data) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getPrivateDownloads()
    {
        let url = `${this.#api}/private/all`;
        return apiService.get(url);
    }

}
